<template>
  <div class="lds-ripple loader">
    <div></div>
    <div></div>
  </div>
</template>
<script>
export default {
  name: "loader",
};
</script>
<style lang="scss">
.lds-ripple {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.9);
}
.lds-ripple div {
  right: 0;
  /* left: 0; */
  margin: auto;
  bottom: 0;
  /* top: 0; */
  position: absolute;
  border: 4px solid #65d1d1;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
