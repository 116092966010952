// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";

import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/user/update-profile",
        name: "update_profile",
        component: () => import("../views/Auth/UpdateProfile.vue"),
      },
      // Start Manage Users
      {
        path: "/users",
        name: "users",
        component: () => import("../views/User/Users.vue"),
      },
      {
        path: "/new-user",
        name: "NewUser",
        component: () => import("../views/User/NewUser.vue"),
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("../views/User/Roles.vue"),
      },
      {
        path: "/add-role",
        name: "addRole",
        component: () => import("../views/User/AddRole.vue"),
      },
      {
        path: "/edit-role/:id",
        name: "Edit Role",
        component: () => import("../views/User/EditRole.vue"),
      },
      // End Manage Users
      // Start Orders
      {
        path: "/orders",
        name: "orders",
        component: () => import("../views/Orders/Orders.vue"),
      },
      {
        path: "/orders/:id",
        name: "order",
        component: () => import("../views/Orders/Order.vue"),
      },
      {
        path: "/requested-orders",
        name: "requested_orders",
        component: () => import("../views/Orders/RequestedOrders.vue"),
      },
      // End Orders
      // Start Couriers
      {
        path: "/couriers",
        name: "couriers",
        component: () => import("../views/Couriers/Couriers.vue"),
      },
      {
        path: "/couriers/:id",
        name: "courier_profile",
        component: () => import("../views/Couriers/CourierProfile.vue"),
      },
      {
        path: "/courier-form/:id?",
        name: "courier-form",
        component: () => import("../views/Couriers/CourierForm.vue"),
      },
      // End Couriers
      // Start Companies
      {
        path: "/companies",
        name: "companies",
        component: () => import("../views/Companies/Companies.vue"),
      },
      {
        path: "/companies/:id",
        name: "company_profile",
        component: () => import("../views/Companies/CompanyProfile.vue"),
      },
      {
        path: "/company-form/:id?",
        name: "company-form",
        component: () => import("../views/Companies/CompanyForm.vue"),
      },
      {
        path: "/companies-reviews",
        name: "companies-reviews",
        component: () => import("../views/Companies/CompaniesReviews.vue"),
      },
      {
        path: "/company-reviews/:id",
        name: "company-reviews",
        component: () => import("../views/Companies/CompanyReviews.vue"),
      },
      // End Companies
      // Start Traders
      {
        path: "/traders",
        name: "traders",
        component: () => import("../views/Traders/Traders.vue"),
      },
      {
        path: "/traders/:id",
        name: "trader_profile",
        component: () => import("../views/Traders/TraderProfile.vue"),
      },
      {
        path: "/trader-form/:id?",
        name: "trader-form",
        component: () => import("../views/Traders/TraderForm.vue"),
      },
      // End Traders
      // Start Categories
      {
        path: "/categories",
        name: "categories",
        component: () => import("../views/Categories/Categories.vue"),
      },
      {
        path: "/category/:id",
        name: "sub-categories",
        component: () => import("../views/Categories/SubCategories.vue"),
      },
      // End Categories
      // Start Shipping Types
      {
        path: "/shippings",
        name: "shippings",
        component: () => import("../views/Shippings/Shippings.vue"),
      },
      {
        path: "/shippings/:id",
        name: "sub-shipping",
        component: () => import("../views/Shippings/SubShipping.vue"),
      },
      // End Shipping Types
      // Start Wallet
      {
        path: "/transactions",
        name: "transactions",
        component: () => import("../views/Wallet/Transactions.vue"),
      },
      // End Wallet
      // Start Reports
      {
        path: "/generate-report",
        name: "generate report",
        component: () => import("../views/Reports/GenerateReport.vue"),
        props: true,
      },
      {
        path: "/overall-report",
        name: "overall report",
        component: () => import("../views/Reports/OverallReport.vue"),
        props: true,
      },
      {
        path: "/receivers-phone",
        name: "receivers Phone",
        component: () => import("../views/Reports/ReceiversPhone.vue"),
        props: true,
      },
      // Ebd Reports
      {
        path: "/cites",
        name: "cites",
        component: () => import("../views/Cites/Cites.vue"),
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("../views/Customers/Customers.vue"),
      },
      {
        path: "/customers/:id",
        name: "customer_profile",
        component: () => import("../views/Customers/CustomerProfile.vue"),
      },
      {
        path: "/customers-feedback",
        name: "customers-feedback",
        component: () => import("../views/Feedback/CustomersFeedback.vue"),
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("../views/Feedback/Feedback.vue"),
      },
      {
        path: "/audit-logs",
        name: "audit-logs",
        component: () => import("../views/AuditLogs/AuditLogs.vue"),
      },
      {
        path: "/time-slots",
        name: "time-slots",
        component: () => import("../views/TimeSlots/TimeSlots.vue"),
      },
      {
        path: "/banners",
        name: "banners",
        component: () => import("../views/Banners/Banners.vue"),
      },
      {
        path: "/barcodes",
        name: "barcodes",
        component: () => import("../views/Barcodes/Barcodes.vue"),
      },
      {
        path: "/coupons",
        name: "coupons",
        component: () => import("../views/Coupons/Coupons.vue"),
      },
      {
        path: "/coupon-requests",
        name: "coupon-requests",
        component: () => import("../views/Coupons/CouponRequests.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/About/About.vue"),
      },
      {
        path: "/messages",
        name: "messages",
        component: () => import("../views/Messages/Messages.vue"),
      },
      {
        path: "/newsletter",
        name: "newsletter",
        component: () => import("../views/Newsletter/NewsLetter.vue"),
      },
      {
        path: "/sms",
        name: "sms",
        component: () => import("../views/Sms/Sms.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("../views/Settings/Settings.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    name: "Login",
    component: AuthLayout,
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/Login.vue"),
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  // routes,
  // mode: 'history'
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
