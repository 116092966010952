<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loginStatus: "",
      mobile: null,
      mobileNav: true,
      windowWidth: null,
    };
  },
  mounted() {
    this.updateToken();
  },
  computed: {
    isLogged() {
      // console.log("Status" + this.$store.getters.isLogged);
      return this.$store.getters.isLogged;
    },
  },
  methods: {
    updateToken() {
      let nqalAdminLoginData = JSON.parse(
        localStorage.getItem("nqalAdminLoginData")
      );
      if (nqalAdminLoginData != null) {
        this.$store.commit("setNqalAdminLoginData", nqalAdminLoginData);
      } else {
        //
      }
    },
    logout() {
      this.$store.dispatch("Logout").then((path) => {
        this.$router.push(path);
      });
    },
  },
};
</script>
