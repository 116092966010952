<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Nqal"
      title="Nqal"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <div class="dropdown-divider"></div>
        <!-- Start Couriers -->
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers_online'),
            icon: 'fas fa-bicycle text-primary',
            path: '/couriers?type=online',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers_offline'),
            icon: 'fas fa-bicycle text-primary',
            path: '/couriers?type=offline',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers_inactive'),
            icon: 'fas fa-bicycle text-primary',
            path: '/couriers?type=inactive',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers_companiesCouriers'),
            icon: 'fas fa-bicycle text-primary',
            path: '/couriers?type=companiesCouriers',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers_freelancers'),
            icon: 'fas fa-bicycle text-primary',
            path: '/couriers?type=freelancers',
          }"
        />
        <!-- End Couriers -->
        <div class="dropdown-divider"></div>
        <!-- Start Companies -->
        <sidebar-item
          :link="{
            name: $t('sidebar.all_companies'),
            icon: 'ni far fa-building text-primary',
            path: '/companies',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.all_companies_reviews'),
            icon: 'far fa-comments text-primary',
            path: '/companies-reviews',
          }"
        />
        <!-- End Companies -->
        <div class="dropdown-divider"></div>
        <!-- Start Traders -->
        <sidebar-item
          :link="{
            name: $t('sidebar.all_traders'),
            icon: 'ni fas fa-male text-primary',
            path: '/traders',
          }"
        />
        <!-- End Traders -->
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.customers'),
            icon: 'ni fas fa-user text-primary',
            path: '/customers',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.customers_feedback'),
            icon: 'ni far fa-comment-dots text-primary',
            path: '/customers-feedback',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.feedback'),
            icon: 'ni far fa-comment-dots text-primary',
            path: '/feedback',
          }"
        />
        <div class="dropdown-divider"></div>
        <!-- Start Orders -->
        <sidebar-item
          :link="{
            name: $t('sidebar.orders'),
            icon: 'ni fas fa-boxes text-primary',
            path: '/orders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.requested_orders'),
            icon: 'ni fas fa-box text-primary',
            path: '/requested-orders',
          }"
        />
        <!-- End Orders -->
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.all_transactions'),
            icon: 'ni fas fa-credit-card text-primary',
            path: '/transactions',
          }"
        />
        <div class="dropdown-divider"></div>
        <!-- Start Users -->
        <sidebar-item
          :link="{
            name: $t('sidebar.all users'),
            icon: 'ni fas fa-users text-primary',
            path: '/users',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.new user'),
            icon: 'ni fas fa-user-plus text-primary',
            path: '/new-user',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.roles'),
            icon: 'ni fas fa-user-tag text-primary',
            path: '/roles',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.add role'),
            icon: 'ni fas fa-user-shield text-primary',
            path: '/add-role',
          }"
        />
        <!-- End Users -->
        <div class="dropdown-divider"></div>
        <!-- Start Categories -->
        <sidebar-item
          :link="{
            name: $t('sidebar.all_categories'),
            icon: 'ni fas fa-boxes text-primary',
            path: '/categories',
          }"
        />
        <!-- End Categories -->
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.generate_report'),
            icon: 'ni far fa-file-alt text-primary',
            path: '/generate-report',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.overall_report'),
            icon: 'ni far fa-file-alt text-primary',
            path: '/overall-report',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.receivers_phone'),
            icon: 'ni fas fa-phone text-primary',
            path: '/receivers-phone',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.all_cites'),
            icon: 'ni fas fa-city text-primary',
            path: '/cites',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.all_shipping_types'),
            icon: 'ni fas fa-dolly-flatbed text-primary',
            path: '/shippings',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.banners'),
            icon: 'ni far fa-images text-primary',
            path: '/banners',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.barcode'),
            icon: 'ni fas fa-barcode text-primary',
            path: '/barcodes',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.coupons'),
            icon: 'ni fas fa-tags text-primary',
            path: '/coupons',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.coupon_requests'),
            icon: 'ni fas fa-tags text-primary',
            path: '/coupon-requests',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.about'),
            icon: 'ni far fa-address-card text-primary',
            path: '/about',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.messages'),
            icon: 'ni far fa-comments text-primary',
            path: '/messages',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.newsletter'),
            icon: 'ni fas fa-mail-bulk text-primary',
            path: '/newsletter',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.sms'),
            icon: 'ni fas fa-sms text-primary',
            path: '/sms',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.audit_logs'),
            icon: 'ni fas fa-tv text-primary',
            path: '/audit-logs',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.settings'),
            icon: 'ni fas fa-cogs text-primary',
            path: '/settings',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.time_slots'),
            icon: 'ni fas fa-hourglass-half text-primary',
            path: '/time-slots',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
