import axios from "axios";
export default {
  state: {
    nqalAdminToken: false,
    nqalAdminLoginData: false,
    error: "Null",
  },
  getters: {
    isLogged(state) {
      return !!state.nqalAdminToken;
    },
    isError(state) {
      return state.error;
    },
    isToken(state) {
      return state.nqalAdminToken;
    },
    memberName(state) {
      return state.nqalAdminLoginData.name;
    },
    memberAvatar(state) {
      return state.nqalAdminLoginData.avatar;
    },
  },
  mutations: {
    setNqalAdminLoginData(state, nqalAdminData) {
      state.nqalAdminToken = nqalAdminData.access_token;
      state.nqalAdminLoginData = nqalAdminData;
      localStorage.setItem(
        "nqalAdminToken",
        JSON.stringify(nqalAdminData.access_token)
      );
      localStorage.setItem(
        "nqalAdminLoginData",
        JSON.stringify(nqalAdminData)
      );
      axios.defaults.headers.common.Authorization = `Bearer ${nqalAdminData.access_token}`;
    },
    setLoginError(state, error) {
      state.error = error;
    },
    setLogout(state) {
      state.nqalAdminToken = null;
      state.nqalAdminLoginData = null;
      // localStorage.removeItem('nqalAdminToken','nqalAdminLoginData');
    },
  },
  actions: {
    Login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("admin/administration/members/login", payload)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                commit("setNqalAdminLoginData", res.data.data[0]);
              } else {
                commit("setLoginError", res.data.message);
              }
            }
            resolve("/dashboard");
          })
          .catch((err) => {
            reject(err);
            console.log("Something went wrong Catch => " + err);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve) => {
        axios.post("admin/administration/members/logout").then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              commit("setLogout");
              localStorage.removeItem("nqalAdminToken");
              localStorage.removeItem("nqalAdminLoginData");
              delete axios.defaults.headers.common["Authorization"];
            } else {
              console.log(res.data);
            }
          }
          resolve("/login");
        });

        // resolve("/login");
      });
    },  
    Update({ commit }, updatedData) {
      commit("setNqalAdminLoginData", updatedData);
    }, 
  },
};
