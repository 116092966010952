<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <h2 class="text-white">{{ $t("sidebar.couriers_management") }}</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-blue"
            :sub-title="$t('sidebar.couriers_online')"
            href="couriers?type=online"
            icon="fas fa-bicycle"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-blue"
            :sub-title="$t('sidebar.couriers_offline')"
            href="couriers?type=offline"
            icon="fas fa-bicycle"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-blue"
            :sub-title="$t('sidebar.couriers_inactive')"
            href="couriers?type=inactive"
            icon="fas fa-bicycle"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-blue"
            :sub-title="$t('sidebar.couriers_companiesCouriers')"
            href="couriers?type=companiesCouriers"
            icon="fas fa-bicycle"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-blue"
            :sub-title="$t('sidebar.couriers_freelancers')"
            href="couriers?type=freelancers"
            icon="fas fa-bicycle"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
      <h2 class="text-white">
        {{ $t("sidebar.companies_traders_management") }}
      </h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-purple"
            :sub-title="$t('sidebar.all_companies')"
            href="companies"
            icon="far fa-building"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-purple"
            :sub-title="$t('sidebar.all_companies_reviews')"
            href="companies-reviews"
            icon="far fa-comments"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-purple"
            :sub-title="$t('sidebar.all_traders')"
            href="traders"
            icon="fas fa-male"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
      <h2 class="text-white">
        {{ $t("sidebar.customers_feedback_management") }}
      </h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-yellow"
            :sub-title="$t('sidebar.customers')"
            href="customers"
            icon="fas fa-user"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-yellow"
            :sub-title="$t('sidebar.customers_feedback')"
            href="customers-feedback"
            icon="far fa-comment-dots"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-yellow"
            :sub-title="$t('sidebar.feedback')"
            href="feedback"
            icon="far fa-comment-dots"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
      <h2 class="text-white">{{ $t("sidebar.orders_trans_management") }}</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-green"
            :sub-title="$t('sidebar.orders')"
            href="orders"
            icon="fas fa-boxes"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-green"
            :sub-title="$t('sidebar.requested_orders')"
            href="requested-orders"
            icon="fas fa-box"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-green"
            :sub-title="$t('sidebar.all_transactions')"
            href="transactions"
            icon="fas fa-credit-card"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
      <h2 class="text-white">{{ $t("sidebar.general_management") }}</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.all users')"
            href="users"
            icon="fas fa-location-arrow"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.roles')"
            href="roles"
            icon="fas fa-user-tag"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.add role')"
            href="add-role"
            icon="fas fa-user-shield"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.all_categories')"
            href="categories"
            icon="fas fa-boxes"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.generate_report')"
            href="generate-report"
            icon="far fa-file-alt"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.overall_report')"
            href="overall-report"
            icon="far fa-file-alt"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-orange"
            :sub-title="$t('sidebar.receivers_phone')"
            href="receivers-phone"
            icon="fas fa-phone"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
      <h2 class="text-white">{{ $t("sidebar.settings") }}</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.all_cites')"
            href="cites"
            icon="fas fa-city"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.all_shipping_types')"
            href="shippings"
            icon="fas fa-dolly-flatbed"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.banners')"
            href="banners"
            icon="far fa-images"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.barcode')"
            href="barcodes"
            icon="fas fa-barcode"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.coupons')"
            href="coupons"
            icon="fas fa-tags"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.coupon_requests')"
            href="coupon-requests"
            icon="fas fa-tags"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.about')"
            href="about"
            icon="far fa-address-card"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.messages')"
            href="messages"
            icon="far fa-comments"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.newsletter')"
            href="newsletter"
            icon="fas fa-mail-bulk"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.sms')"
            href="sms"
            icon="fas fa-sms"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.audit_logs')"
            href="audit-logs"
            icon="fas fa-tv"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.settings')"
            href="settings"
            icon="fas fa-cogs"
            class="mb-4"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            type="gradient-red"
            :sub-title="$t('sidebar.time_slots')"
            href="time-slots"
            icon="fas fa-hourglass-half"
            class="mb-4"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.bg-gradient-blue {
  background: linear-gradient(87deg, #6dc5c9 0, #6dc5c9 100%) !important;
}
.bg-gradient-purple {
  background: linear-gradient(87deg, #5e88eb 0, #5e72e4a8 100%) !important;
}
</style>
