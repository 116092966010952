import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import "bootstrap/dist/js/bootstrap.js";

import VueExcelXlsx from "vue-excel-xlsx";

// SweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// Translation
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
let userLang = "ar";
if (localStorage.getItem("userLang")) {
  userLang = localStorage.getItem("userLang");
} else {
  userLang = "ar";
}

if (userLang == "ar") {
  require("./assets/style-rtl.css");
} else {
  //
}
const language = {
  en: en,
  ar: ar,
};
const i18n = createI18n({
  locale: userLang,
  messages: language,
});


// Check User Is Authorized To Access
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
axios.interceptors.response.use(
  (response) => {
    if (response.data["code"] && response.data["code"] == "405") {
      router.push({
        name: "dashboard",
      });
      new Swal({
        position: "top-end",
        icon: "error",
        title: response.data["message"],
        showConfirmButton: false,
        timer: 4000,
      });
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("nqalAdminToken");
      localStorage.removeItem("nqalAdminLoginData");
      return router.push("/login");
    }
    return Promise.reject(error.response);
  }
);

// BASE URL
if (process.env.NODE_ENV == "development") {
  // Development
  axios.defaults.baseURL = "https://nqal.co/testNqal/api/";
  console.log("Development Version");
} else {
  // Development
  if (window.location.host == "testadmin.nqal.co") {
    axios.defaults.baseURL = "https://nqal.co/testNqal/api/";
    console.log("Development Version");
    // Production
  } else if (window.location.host == "admin.nqal.co") {
    axios.defaults.baseURL = "https://nqal.co/LiveNqal/api/";
  } else {
    console.log("No API in this host");
  }
}

axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.accept = "application/json";
axios.defaults.headers.accept = "*/*";
(axios.defaults.headers["Accept-Language"] = localStorage.getItem("userLang")
  ? localStorage.getItem("userLang")
  : "ar"),
  // Check if Authorized
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isLogged || localStorage.getItem("nqalAdminToken")) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else if (to.matched.some((record) => record.meta.hideForAuth)) {
      if (store.getters.isLogged || localStorage.getItem("nqalAdminToken")) {
        next({ path: "/" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
const appInstance = createApp(App);
appInstance
  .use(router)
  .use(store)
  .use(ArgonDashboard)
  .use(VueSweetalert2)
  .use(i18n)
  .use(VueExcelXlsx);
appInstance.config.globalProperties.axios = axios;
appInstance.mount("#app");
